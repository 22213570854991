import type { MenuItemType } from '~/shared/types/menu-item.type';

export const USER_MENU: MenuItemType[] = [
  {
    label: 'menu.user.profileSettings',
    to: '/settings',
    icon: 'settings',
  },
  {
    label: 'menu.user.accountSecurity',
    to: '/settings/account-security',
    icon: 'shield-check',
  },
  // {
  //   label: 'menu.user.notifications',
  //   to: '/settings/notifications',
  //   icon: 'bell',
  // },
  // {
  //   label: 'menu.user.contribute',
  //   icon: 'menu-square',
  //   items: [
  //     {
  //       label: 'coins.dapp',
  //       to: '/dashboard/my-dapps',
  //     },
  //     {
  //       label: 'articles.title',
  //       to: '/dashboard/my-articles',
  //     },
  //     {
  //       label: 'news.title',
  //       to: '/dashboard/my-news',
  //     },
  //   ],
  // },
];
