<template>
  <div class="tw-flex tw-items-center tw-gap-3.5 tw-pb-3.5" :class="headerClasses">
    <de-avatar :src="userStore.user.profile.photo" />

    <div>
      <p class="heading-h5">{{ t('common.hiX', { name: userName }) }}</p>
      <p v-if="userStore.user.email" class="body-b1 tw-text-primary-300">
        {{ userStore.user.email }}
      </p>
    </div>
  </div>

  <ul class="tw-flex tw-flex-col tw-gap-1.5 tw-pt-1.5 xl:tw-pt-3.5">
    <li v-for="item in USER_MENU" :key="item.label">
      <app-user-menu-list-item
        :item="item"
        :menu-item-class="menuItemClasses"
        @navigate="$emit('navigate')"
      />
    </li>

    <li>
      <button
        class="tw-flex tw-items-center tw-w-full tw-p-2 hover:tw-bg-primary-700 tw-cursor-pointer tw-text-left"
        :class="menuItemClasses"
        @click="onLogoutClick"
      >
        <de-svg-icon name="power-button" class="tw-text-primary-400 tw-mr-2" />
        <span class="heading-h4 xl:heading-h5">{{ t('menu.user.logOut') }}</span>
      </button>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import DeAvatar from '~/shared/components/lib/avatar/DeAvatar.vue';
import { useLogin } from '~/shared/composable/useLogin';
import { USER_MENU } from '~/data/config/user-menu.config';
import AppUserMenuListItem from '~/shared/components/menu/AppUserMenuListItem.vue';
import DeSvgIcon from '~/shared/components/lib/svg-icon/DeSvgIcon.vue';
import { useUserStore } from '~/store/user';

defineProps({
  headerClasses: {
    type: String,
    default: null,
  },
  menuItemClasses: {
    type: String,
    default: null,
  },
});

defineEmits(['navigate']);

const { t } = useI18n();
const userStore = useUserStore();
const { logout } = useLogin();

async function onLogoutClick() {
  await logout();
}

const userName = computed(
  () =>
    userStore.user.profile.name || userStore.user.username || userStore.user.email?.split('@')[0],
);
</script>
